<template>
  <div
    v-if="profile"
    class="page container p-4"
  >
    <div class="columns">
      <div
        class="column"
        :class="contentWidthClass"
      >
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            :src="currentUserAvatar"
            alt="identifícate"
          >
        </div>
        <b-tabs
          type="is-toggle"
          position="is-centered"
        >
          <b-tab-item
            value="profile"
          >
            <template #header>
              <span>Perfil</span>
            </template>
            <form @submit.prevent="update">
              <h4 class="is-size-4 mb-2">
                Perfil
              </h4>
              <b-field>
                <b-input
                  ref="firstname"
                  v-model="profile.firstname"
                  type="text"
                  placeholder="Nombre"
                  required
                  maxlength="100"
                  :has-counter="false"
                />
              </b-field>
              <b-field>
                <b-input
                  ref="lastname"
                  v-model="profile.lastname"
                  type="text"
                  placeholder="Apellidos"
                  required
                  maxlength="100"
                  :has-counter="false"
                />
              </b-field>
              <b-field
                :message="telMsg || defaultTelMsg"
                :type="!!telMsg?'is-danger':null"
              >
                <vue-tel-input
                  v-model="profile.phone"
                  default-country="ES"
                  :input-options="telInputOptions"
                  @validate="phoneValidate"
                  @open="telOpen = true"
                  @close="telOpen = false"
                >
                  <template #arrow-icon>
                    <span v-if="telOpen">▲</span> <span v-else>▼</span>
                  </template>
                </vue-tel-input>
              </b-field>
              <b-field>
                <b-input
                  v-model="profile.school"
                  type="text"
                  placeholder="Escuela"
                  disabled
                />
              </b-field>
              <b-field>
                <b-input
                  v-model="profile.email"
                  type="text"
                  placeholder="Email"
                  disabled
                />
              </b-field>
              <div class="mt-3">
                <b-button
                  type="is-primary is-uppercase mb-3"
                  native-type="submit"
                  expanded
                >
                  Actualizar
                </b-button>
                <b-button
                  type="is-danger is-uppercase mb-3"
                  expanded
                  @click="logout"
                >
                  Desconectar
                </b-button>
              </div>
            </form>
          </b-tab-item>
          <b-tab-item
            value="method-codes"
          >
            <template #header>
              <span>Mis códigos</span>
            </template>
            <user-method-codes
              v-if="profile"
              :codes="profile.codes"
            />
          </b-tab-item>
          <b-tab-item
            value="notifications"
          >
            <template #header>
              <span>
                <b-tag
                  v-if="unreadNotifications > 0"
                  type="is-danger"
                  rounded
                >  {{ unreadNotifications }} </b-tag> Notificaciones</span>
            </template>
            <user-notifications />
          </b-tab-item>
          <b-tab-item
            value="birthdays"
          >
            <template #header>
              <span>Cumpleaños</span>
            </template>
            <user-birthdays />
          </b-tab-item>
          <b-tab-item
            v-if="authUserMustViewNextBirthdays"
            value="next-birthdays"
          >
            <template #header>
              <span>Próximos cumpleaños</span>
            </template>
            <user-next-birthdays />
          </b-tab-item>
          <b-tab-item
            v-if="authUserMustViewSchoolGroups"
            value="school-groups"
          >
            <template #header>
              <span>Grupos</span>
            </template>
            <user-school-method-groups />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <LegalFooter />
  </div>
</template>

<script>

import md5 from "md5"
import dayjs from 'dayjs'
import deviceTokenMixin from "@/mixins/deviceTokenMixin";
const UserNotifications = ()=> import("@/components/profile/UserNotifications")
const UserMethodCodes = ()=> import("@/components/profile/UserMethodCodes")
const UserBirthdays = ()=> import("@/components/profile/UserBirthdays")
const LegalFooter = ()=> import("@/components/legal/LegalFooter")
const UserNextBirthdays = ()=> import("@/components/profile/UserNextBirthdays")
const UserSchoolMethodGroups = ()=> import("@/components/profile/UserSchoolMethodGroups")

export default {
  name: 'Profile',
  components: {UserSchoolMethodGroups, UserNextBirthdays, LegalFooter, UserBirthdays, UserMethodCodes, UserNotifications},
  title: 'Mi perfil',
  mixins: [deviceTokenMixin],
  data: function () {
    return {
      isValid: false,
      gravatarUrl: 'https://s.gravatar.com/avatar/{{emailmd5}}?s=86',
      profile: null,
      telTO: null,
      telInputOptions: {
        placeholder: 'Teléfono'
      },
      telOpen: false,
      defaultTelMsg: 'Introduce tu teléfono móvil si quieres que contactemos contigo mediante WhatsApp',
      telMsg: ''
    }
  },
  computed: {
    contentWidthClass(){
      if(this.isAuth && this.authUserMustViewNextBirthdays && this.authUserMustViewSchoolGroups){
        return 'is-8 is-offset-2'
      }
      return 'is-6 is-offset-3'
    },
    unreadNotifications() {
      return this.$store.getters.unreadNotifications
    },
    currentUserAvatar() {
      if (!this.profile) {
        return "@/assets/img/anon-avatar.png"
      }

      const md5email = md5(this.profile.email)

      return this.gravatarUrl.replace('{{emailmd5}}', md5email)
    },
    authUserMustViewNextBirthdays(){
        if(!this.isAuth){
          return false
        }

      const authUser = this.$store.state.user

      return authUser.level >= 20
    },
    authUserMustViewSchoolGroups(){
      if(!this.isAuth){
        return false
      }

      const authUser = this.$store.state.user

      return authUser.level >= 20
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        await this.$store.dispatch('initLoading')

        this.profile = await this.$api.user.getProfile()

        this.profile.codes = this.profile.codes.map((item) => {
          item['used_at'] = dayjs(item.used_at).format('DD/MM/YYYY HH:mm')
          item['expires_at'] = dayjs(item.expires_at).format('DD/MM/YYYY HH:mm')
          return item
        })

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        const data = error.response.data
        this.$msg.error(data.message)
      }
    },
    async update() {
      try {
        await this.$store.dispatch('initLoading')
        let userData = {
          firstname: this.profile.firstname,
          lastname: this.profile.lastname,
          phone: this.profile.phone
        }

        this.profile = await this.$api.user.updateProfile(userData)

        await this.$store.dispatch('finishLoading')

        this.$buefy.snackbar.open({
          duration: 3000,
          message: 'Perfil actualizado',
          type: 'is-success',
          position: 'is-bottom',
          actionText: 'Cerrar'
        })

        await this.$router.push({name: 'home'})
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        console.error(error)

        let messageText = 'Se ha producido un error al actualizar el perfil de usuario'
        if (error.response && error.response.status === 403) {
          //console.log(error.response.data);
          messageText = 'La sesión de usuario ha expirado. Vuelve a identificarte'
        }
        this.$msg.error(messageText)
        await this.$router.push({name: 'home'})
      }
    },
    async logout() {
      try {
        await this.$store.dispatch('initLoading')

        await this.$api.user.logout()

        await this.$store.dispatch('finishLoading')
        await this.$store.dispatch('logout')

        await this.updateStoredToken()

        this.$msg.show('¡Hasta luego!, esperamos volverte a ver en breve.')

        await this.$router.push({name: 'home'})
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        console.error(error)

        let messageText = 'Se ha producido un error al cerrar la sesión'
        this.$msg.error(messageText)
        await this.$router.push({name: 'home'})
      }
    },
    phoneValidate(data) {
      if (data.valid === false) {
        this.telMsg = 'Número de teléfono incorrecto'
        if (this.telTO) {
          clearTimeout(this.telTO)
          this.telTO = null
        }
        this.telTO = setTimeout(() => {
          this.telMsg = ''
        }, 5000)
      }
    }
  }
}
</script>
